import { sanityIoImageLoader } from '@core/utils'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

const Footer = () => {
    return (
        <div className="flex flex-row justify-center bg-black w-full">
            <div className="flex md:flex-row flex-col justify-between md:items-center items-start p-4 max-w-[1440px] w-full">
                <Image
                    loader={sanityIoImageLoader}
                    src={"https://jhattse.com/api/v1/file/?key=esangrah/P1BX9YMA_bottomimage.png"}
                    width="200"
                    height="200"
                    alt="Esangrah Technologies Pvt. Ltd. Company Logo"
                    className="h-14 w-auto"
                    priority={true}
                />
                <div className="flex md:flex-row pt-10 flex-col gap-8">
                    <div className="justify-center items-center">
                        <p className="text-neutral-50 md:text-xl text-base pb-4 font-semibold">USEFUL LINKS</p>
                        <p className="text-white md:text-lg text-sm hover:font-semibold font-medium whitespace-nowrap"><Link href="/about">About Us</Link></p>
                        <p className="text-white md:text-lg text-sm hover:font-semibold font-medium whitespace-nowrap"><Link href="https://jhattse.com">Jhattse</Link></p>
                        <p className="text-white md:text-lg text-sm hover:font-semibold font-medium whitespace-nowrap"><Link href="https://business.jhattse.com">Jhattse Business</Link></p>
                        <p className="text-white md:text-lg text-sm hover:font-semibold font-medium whitespace-nowrap"><Link href="https://jhattse.com/terms" target="_blank">Terms of Use</Link></p>
                    </div>
                    <div className="justify-center items-center">
                        <p className="text-neutral-50 md:text-xl text-base font-semibold pb-4">
                            GET IN TOUCH
                        </p>
                        <p className="text-white md:text-lg text-sm font-medium">
                            Esangrah Technologies Pvt. Ltd.
                        </p>
                        <p className="text-white md:text-lg text-sm font-medium">
                            Dehradun, Uttarakhand
                        </p>
                        <p className="text-white md:text-lg text-sm font-medium pt-2">
                            contact@esangrah.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer