import React from "react";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";
import { CarouselContainer } from "@components/container/carousel";
import { MdKeyboardArrowRight } from "react-icons/md";
import Link from "next/link";
import Navbar from "@components/company/navbar";
import { sanityIoImageLoader } from "@core/utils";
import Footer from "@components/company/footer";
import Head from "next/head";
function company() {
    const teams = [{name: "Manish", designation: "Data Analyst", description:"Task and data juggler handling everything a customer may need. He might be checking somewhere the news and data trends if you don't find him easily.", image: "https://jhattse.com/api/v1/file/?key=jhattse/employee/5WY9PY94_manish.jpeg"}, {name: "Jay", designation: "Software Engineer", image: "https://jhattse.com/api/v1/file/?key=jhattse/employee/U2S68OW3_Jai.jpeg", description: "Jay handle the frontend requriements so if you are looking for anything fancy in the software. He is the one deliverying."}, {name: "Vikas", designation: "Software Engineer", image: "https://jhattse.com/api/v1/file/?key=jhattse/employee/85Z5VEQU_Vikas.png", description: "Vikas is our Mr. dependable handling the design and frontend changes and going back and forth with our customers changing requirements and handling the product stability."}]
  return (
    <>
    <Head>
        <title>Esangrah Technologies Pvt. Ltd.</title>
        <meta name="description" content="Esangrah Technologies provides a ecosystem of digital products and SASS services to business for helping them in business automation and digitalization." />
        <meta name="og:title" content="Esangrah Technolgies Pvt. Ltd." />
        <meta name="og:description" content="Esangrah Technologies provides a ecosystem of digital products and SASS services to business for helping them in business automation and digitalization." />
        <meta name="og:image" content="https://jhattse.com/api/v1/file/?key=esangrah/Z3R2MJ5D_headerimage.png" />
        <meta property="og:url" content="https://esangrah.com" />
        <link rel="canonical" href="https://esangrah.com" />
        <link rel="shortcut icon" href="https://jhattse.com/api/v1/file/?key=esangrah/Z3R2MJ5D_headerimage.png" />
    </Head>
    <div className="flex flex-col justifty-center items-center font-manrope">
      {/* Navber */}
      <Navbar />
      {/* Navber */}

      {/* Hero Section */}
    <div className="flex flex-col justify-center max-w-[1440px] w-full">
        <div className="flex md:flex-row flex-col gap-4 justify-center md:items-center items-start pt-10 px-4">
          <div className="flex flex-col justify-center md:items-start items-center w-full">
            <h1>
                <strong className="lg:text-6xl md:text-4xl text-2xl font-extrabold text-darkGray md:py-4 py-2">
                  We provide 360° solutions for
                </strong>
                <strong className="text-green-700 lg:text-7xl md:text-5xl text-3xl font-extrabold">
                  Businesses
                </strong>
            </h1>
            <div className="flex items-center gap-4 md:pt-10 pt-4">
                <div className="text-white">
                <Link href={"#product"} className="text-white flex items-center justify-center gap-2 md:text-lg text-base font-bold rounded-lg border border-solid bg-green-700 md:px-4 p-2 whitespace-nowrap">
                    OUR PRODUCTS
                    <MdKeyboardArrowRight />
                </Link>
              </div>
              <div className="text-green-700">
                <a href="https://esangrah.com/about" className="text-green-700 flex items-center justify-center gap-2 md:text-lg text-base font-bold rounded-lg border border-solid border-green-700 md:px-4 p-2 whitespace-nowrap">
                    ABOUT US
                    <MdKeyboardArrowRight />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full text-center">
            <Image
                loader={sanityIoImageLoader}
                src={"https://jhattse.com/api/v1/file/?key=esangrah/OP263XX2_mainimage.png"}
                width="729"
                height="640"
                alt="Kirana Store and an attendent"
                className="md:w-auto w-full h-auto md:h-full max-w-110"
                priority={true}
            />
          </div>
        </div>
        <div className="h-16 lt-sm:h-4"></div>
        <div className="grid sm:grid-cols-1 md:grid-cols-3 py-10 px-4">
          <div className="py-2 row-span-1 md:col-span-2">
            <p className="lg:text-4xl md:text-2xl text-xl text-black font-bold md:pb-10 pb-5">
              Who are We?
            </p>
            <div className="flex flex-col gap-4 md:w-4/5 w-full">
              <p className="text-neutral-600 font-medium md:text-lg text-sm">
                Esangrah Technologies founded in early 2023 envision to create
                ecosystem for India's next digital leap. We as organisation
                strive to provide the best available tech to entrepreneurs,
                businesses and customers. We feel that these digital tools are
                necessity to remove out any friction and challenges in the
                digital adoption.
              </p>
              <p className="text-neutral-600 font-medium md:text-lg text-sm">
                Businesses can benefit heavily from our best and low cost products creating
                multi touch points with customers and enhancing and managing their
                business in the digital era with ease.
              </p>
            </div>
          </div>
          <div className="row-span-1 md:col-span-1">
            <Image
              loader={sanityIoImageLoader}
              src={"https://jhattse.com/api/v1/file/?key=esangrah/LTGK3TK3_secondimage.png"}
              width="365"
              height="320"
              alt="Showing connect and trust"
              className="hidden md:flex w-full md:h-auto"
              priority={true}
            />
          </div>
        </div>
        <div className="md:py-10 py-5 px-4" id="product">
          <p className="text-black md:text-2xl text-xl font-bold pb-4">
            Our Products
          </p>
          <CarouselContainer>
            <SwiperSlide style={{ width: "auto" }} className="min-w-0">
              <Link href={"https://business.jhattse.com"} target="_blank"><div className="rounded-xl bg-black md:h-36 h-18 p-4 flex items-center">
                <Image
                  loader={sanityIoImageLoader}
                  src={"https://cdn.jhattse.com/business/business/small-jhattse.svg?v=1"}
                  width="200"
                  height="200"
                  alt="Jhattse Business Logo"
                  className="w-44 h-auto"
                  priority={true}
                />
              </div></Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }} className="min-w-0">
              <Link href={"https://jhattse.com"} target="_blank"><div className="rounded-xl bg-black md:h-36 h-18 p-4 flex items-center">
                <Image
                  loader={sanityIoImageLoader}
                  src={"https://cdn.jhattse.com/public/company/jhattse.png"}
                  width="200"
                  height="200"
                  alt="Jhattse Logo"
                  className="w-44 h-auto"
                  priority={true}
                />
              </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide style={{ width: "auto" }} className="min-w-0">
              <Link href={"#ovalcomingsoon"} target="_blank"><div className="rounded-xl bg-black md:h-36 h-18 p-4 flex items-center bg-stone-600">
                <p className="text-white text-center md:text-3xl text-sm font-bold">
                  Oval Coming Soon!
                </p>
              </div></Link>
            </SwiperSlide>
          </CarouselContainer>
        </div>

        <div className="md:py-10 py-10 px-4">
          <p className="text-black md:text-2xl text-xl font-bold pb-4">
            Our Team
          </p>

          <CarouselContainer>
            {
                teams.map((tm, index) => {
                    return (
                        <SwiperSlide style={{ width: "auto" }} className="min-w-0">
                            <>
                                <div className="relative transform py-2 transition ease-in-out [transform-style:preserve-3d] hover:[transform:rotateY(180deg)] duration-500 delay-[var(--delay)]" key={index}>
                                    <div>
                                        <Image
                                        loader={sanityIoImageLoader}
                                        src={tm.image}
                                        width="360"
                                        height="360"
                                        alt="Team members"
                                        className="grayscale md:w-60 w-40 h-auto aspect-square"
                                        />
                                    </div>
                                    <div className="absolute inset-0 w-full [transform:rotateY(180deg)] [backface-visibility:hidden] overflow-hidden">
                                        <div className="h-full">
                                            <div className="relative flex w-full h-full grow">
                                                <p className="absolute p-2 top-0 md:text-lg text-md bg-green-700 text-white font-manrope h-full">{tm?.description || "Too shy to share anything"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="py-2">
                                    <p className="font-bold text-black md:text-2xl text-base">
                                        {tm.name}
                                    </p>
                                    <p className="font-medium text-black md:text-lg text-sm">
                                        {tm.designation}
                                    </p>
                                </div>
                                </>
                            </SwiperSlide>
                    )
                })
            }
          </CarouselContainer>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* footer */}
      {/* Drop Down Menu */}

    </div>
    </>
  );
}

export default company;