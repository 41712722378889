import { sanityIoImageLoader } from '@core/utils'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { MdMenu } from 'react-icons/md'
import Sidebar from './sidebar'

const Navbar = () => {
    const [showSidebar, setShowSidebar] = useState(false)

    return (
        <div className="flex flex-row bg-white justify-center items-center w-full">
            <div className="flex flex-row justify-between items-center px-4 h-20 max-w-[1440px] w-full">
                <Link href={"/"} className="w-full h-14">
                    <Image
                        priority={true}
                        loader={sanityIoImageLoader}
                        src={"https://jhattse.com/api/v1/file/?key=esangrah/Z3R2MJ5D_headerimage.png"}
                        width="200"
                        height="200"
                        alt="Esangrah Technologies Pvt. Ltd. Company logo"
                        className="w-auto h-14"
                /></Link>
                <div className="w-full flex justify-end items-center gap-4" onClick={() => setShowSidebar(!showSidebar)}>
                    <MdMenu size={30} className="md:hidden block" />
                </div>

                <div className="md:flex flex-row justify-end items-center w-3/4 gap-8 hidden">
                    <Link href={"https://wellfound.com/company/esangrah-technologies-1"}
                        target="_blank"
                        className="text-custom_gray md:text-lg text-base font-semibold">
                        CAREERS
                    </Link>
                    <Link href={"/"} className="text-custom_gray md:text-lg text-base font-semibold whitespace-nowrap">
                        CONTACT US
                    </Link>
                </div>
                <Sidebar
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                />
            </div>
        </div>
    )
}

export default Navbar